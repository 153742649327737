
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "InfoModal",
})
export default class ModalPopupConfirm extends Vue {
  @Prop({ default: "" }) givenTitle!: string;
  @Prop({ default: "" }) givenDescription!: string;

  onClicked() {
    this.$bvModal.hide("informationModal");
  }
}
