import Chart, { ChartConfiguration, ChartItem } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
export const PieChartColors = [
  "#606aaf",
  "#CCCCCC",
  "#BDBFDA",
  "#9ab185",
  "#ECD3AC",
  "#BED0DA",
  "#BCBFDA"
];
export const drawPieChart = (
  id: string,
  pielabel: string[],
  piedata: number[]
) => {
  const chartDiv = document.getElementById(id);

  const datas = {
    labels: pielabel,
    datasets: [
      {
        label: "",
        data: piedata,
        backgroundColor: PieChartColors,
        hoverOffset: 4,
      },
    ],
  };

  const config = {
    type: "pie",
    data: datas,
    plugins: [ChartDataLabels],
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          align: 'center',
          borderRadius: 3,
          color: '#ffffff',
          font: {
            size: 18,
            color: '#ffffff'
          },
          formatter: function(value: any, context: any) {
            if(value>0)
              return String.fromCharCode(context.dataIndex + 1 + 64);
            else
              return "";
          }
        },
      }
    }
  };

  if (chartDiv) {
    const myChart = new Chart(
      chartDiv as ChartItem,
      config as ChartConfiguration
    );
    console.log('pie chart ', myChart);
    
  }
};
